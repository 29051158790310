/* eslint-disable */
const dropdown = `<problem>
  <optionresponse>
    <optioninput>
      <option correct="True"></option>
      <option correct="False"></option>
      <option correct="False"></option>
    </optioninput>
  </optionresponse>
</problem>`

export default dropdown;
