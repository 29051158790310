/* eslint-disable */
const singleSelect = `<problem>
  <multiplechoiceresponse>
    <choicegroup>
      <choice correct="true"></choice>
      <choice correct="false"></choice>
      <choice correct="false"></choice>
    </choicegroup>
  </multiplechoiceresponse>
</problem>`

export default singleSelect;